<template>
  <div>
    <section class="tw-pt-3 tw-sticky tw-top-17 tw-z-10 filter-wrapper">
      <k-container :disable-aside-sticky="true" slots-wrapper-class="tw-space-y-1">
        <template #default>
          <div class="tw-py-3">
            <div v-if="projectsStatus.isLoading" class="tw-flex tw-space-x-6">
              <div class="tw-bg-gray-300 tw-w-24 tw-h-6 tw-rounded-sm tw-animate-pulse"></div>
              <div class="tw-bg-gray-300 tw-w-24 tw-h-6 tw-rounded-sm tw-animate-pulse"></div>
              <div class="tw-bg-gray-300 tw-w-24 tw-h-6 tw-rounded-sm tw-animate-pulse"></div>
              <div class="tw-bg-gray-300 tw-w-24 tw-h-6 tw-rounded-sm tw-animate-pulse"></div>
            </div>

            <div v-else class="tw-flex tw-justify-between tw-items-center tw-gap-x-4">
              <div class="tw-text-base tw-flex tw-overflow-x-auto custom-horizontal-scrollbar tw-gap-x-2">
                <k-button
                  @click="setRouterQuery({ profession: null })"
                  color="outline"
                  :class="{ 'active-filter': appliedFilters.profession === null }"
                  class="md:tw-mb-1"
                >
                  {{ $t('project.actions.all') }} ({{ totalTypesCount }})
                </k-button>

                <k-button
                  v-for="type in professionsWithCount"
                  :key="type.value"
                  @click="setRouterQuery({ profession: type.value })"
                  color="outline"
                  :class="{ 'active-filter': appliedFilters.profession === type.value }"
                  class="md:tw-mb-1"
                >
                  {{ type.displayString }} ({{ type.count }})
                </k-button>
              </div>
            </div>
          </div>
        </template>

        <template #aside>
          <div
            class="tw-flex tw-flex-col-reverse tw-justify-between tw-gap-4 tw-h-full tw-mb-3 tw-mt-0 md:tw--mt-2 md:tw-mb-0 md:tw-flex-row md:tw-items-center"
          >
            <div
              class="tw-flex tw-items-center tw-gap-x-2"
              @click.prevent="setRouterQuery({ isRemoteOnly: !appliedFilters.isRemoteOnly })"
            >
              <label class="switch">
                <input type="checkbox" :checked="appliedFilters.isRemoteOnly" />
                <span class="slider round"></span>
              </label>
              <span class="tw-text-sm tw-leading-relaxed tw-text-gray-800 tw-whitespace-no-wrap">Remote only</span>
            </div>

            <!--                        <k-input-->
            <!--                            :value="appliedFilters.searchTerm"-->
            <!--                            @input="debounceSearchTerm"-->
            <!--                            :placeholder="$t('global.inputs.examples.searchJob')"-->
            <!--                            class="tw-flex-grow grayed-input tw-w-full md:tw-w-auto"-->
            <!--                            append-icon="mdi-magnify"-->
            <!--                        />-->
          </div>
        </template>
      </k-container>
    </section>

    <section class="tw-mt-7 tw-pt-4 tw-pb-8 md:tw-mt-0">
      <k-container :sticky-top="stickyTop">
        <template #default>
          <div v-if="projectsStatus.isLoading" class="tw-space-y-4">
            <project-preview-skeleton />
            <project-preview-skeleton />
            <project-preview-skeleton />
            <project-preview-skeleton />
            <project-preview-skeleton class="tw-opacity-75" />
            <project-preview-skeleton class="tw-opacity-50" />
            <project-preview-skeleton class="tw-opacity-25" />
          </div>

          <div v-else class="tw-space-y-4">
            <project-preview :project="project" v-for="project in allProjects" :key="project.id" />
          </div>
        </template>

        <template #aside v-if="showAside">
          <div class="tw-bg-white tw-rounded-2xl">
            <slot />
          </div>
        </template>
      </k-container>
    </section>
  </div>
</template>

<script>
import ProjectPreviewSkeleton from './project-preview-skeleton-new'
import ProjectPreview from './project-preview-new'
import { mapGetters } from 'vuex'
import KButton from '@web/components/core/k-button'
import { getRouteLocale } from '@web/constants/language'
import { debounce } from 'lodash'

export default {
  name: 'project-list',
  components: {
    KButton,
    ProjectPreview,
    ProjectPreviewSkeleton
  },
  props: {
    showAside: {
      type: Boolean,
      default: false
    },
    stickyTop: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('NEW_PROJECTS', {
      professionsWithCount: 'getProfessionsWithCount',
      allProjects: 'getAllProjects',
      appliedFilters: 'getAppliedFilters'
    }),
    locale() {
      return getRouteLocale()
    },
    totalTypesCount() {
      return this.professionsWithCount.reduce((acc, curr) => acc + curr.count, 0)
    },
    projectsStatus() {
      return this.$store.getters['NEW_PROJECTS/getStatus']('projectsStatus')
    }
  },
  created() {
    if (this.$route.query) {
      const { profession, remote, search } = this.$route.query
      const filter = {
        profession: null,
        isRemoteOnly: false,
        searchTerm: ''
      }

      if (profession && typeof profession === 'string') {
        filter.profession = profession
      }

      if (remote && typeof remote === 'string') {
        filter.isRemoteOnly = !!remote
      }

      if (search && typeof search === 'string') {
        filter.searchTerm = search
      }

      this.setRouterQuery(filter)
    }
  },
  methods: {
    debounceSearchTerm: debounce(function (value) {
      this.setRouterQuery({ searchTerm: value?.trim() })
    }, 500),
    setRouterQuery(filter) {
      this.$store.dispatch(
        'NEW_PROJECTS/setRouteQueryAndFetch',
        {
          filter: { ...this.appliedFilters, ...filter }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
  background: $koderia-gray-100;

  ::v-deep button:active {
    transform: scale(0.97);
  }

  .active-filter {
    ::v-deep button {
      color: $koderia-alt !important;
      border: 1px solid $koderia-alt !important;
      background: $koderia-light !important;
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;

    /* Hide default HTML checkbox */
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $koderia-gray-300;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input {
    &:checked + .slider {
      background-color: $koderia;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $koderia;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(1.5rem);
      -ms-transform: translateX(1.5rem);
      transform: translateX(1.5rem);
    }
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 50px;

    &:before {
      border-radius: 50%;
    }
  }
}

.grayed-input {
  ::v-deep .k-input.v-input {
    .v-input__slot {
      border: 1px solid $koderia-gray-300 !important;
      box-shadow: 0px 3px 0px 0px $koderia-gray-300;

      input {
        font-size: 0.875rem;
      }
    }

    &.v-input--is-focused {
      .v-input__slot {
        box-shadow: 0px 3px 0px 0px $koderia-gray-900 !important;
        border: 1px solid $koderia-gray-900 !important;
      }
    }

    &:not(.v-input--is-focused) {
      .v-input__slot:hover {
        background: #fff !important;
        box-shadow: 0px 3px 0px 0px $koderia-gray-300 !important;
      }
    }
  }
}
</style>
